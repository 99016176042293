import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Usage</AnchorLink>
    </AnchorLinks>
    <h2>{`General guidance`}</h2>
    <p>{`Cards are one of the basic components in waterfall design system. Cards contains content, chart, map, and table.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`Cards are groups that display the similar or same content, information, actions together. We can use cards to separate the content, chart, map, and table in or`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont aspectRatio="1:1" text="A card can stand alone, without relying on surrounding elements for context." mdxType="DoDont" />
  <DoDont type="dont" aspectRatio="1:1" text="A card cannot merge with another card, or divide into multiple cards." mdxType="DoDont" />
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      