import React, { useState, useEffect, useRef } from 'react'

const EmailSignature = () => {
  const [name, setName] = useState('')
  const [role, setRole] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')

  const shadowRootRef = useRef(null)
  useEffect(() => {
    if (shadowRootRef.current) {
      // Check if the Shadow DOM is already attached
      let shadowRoot = shadowRootRef.current.shadowRoot

      if (!shadowRoot) {
        // Attach Shadow DOM only if it doesn't already exist
        shadowRoot = shadowRootRef.current.attachShadow({ mode: 'open' })
      }
      const signatureHTML = `
        <table
          cellPadding="0"
          cellSpacing="0"
          style="border: none; font-family: Helvetica, sans-serif; width: 400px; height: 50px;"
        >
          <tbody>
            <tr style="height: 50px;">
              <td
                style="vertical-align: middle; height: 100%;  width: 120px;"
              >
                <img
                  src="https://storage.googleapis.com/dv01_website_files/dv01_fitch_logo_blue_black_email.png"
                  alt="dv01 Logo"
                  style="width: 110px; height: 42px;  display: block; margin: 0; padding: 0; border: none;"
                />
              </td>
              <td
                style="padding-left: 15px; border-left-width: 2px; border-left-style: solid; border-left-color: #572ae4; vertical-align: top; height: 100%;"
              >
                <p
                  style="margin: 0; font-size: 14px; font-weight: 700; color: #572ae4;"
                >
                  ${name}
                </p>
                <p style="margin: 0 0 4px; font-size: 12px; color: #333; font-weight: 400;">
                  ${role}
                </p>

                <p style="margin: 0; font-size: 12px; color: #333; font-weight: 300;">
                ${
                  phone
                    ? `<strong style="color: #572ae4">M </strong>
                      <a
                        href="tel:${phone}"
                        style="color: #000000; text-decoration: none;"
                      >
                        ${phone}
                      </a>`
                    : ''
                }

                  <strong style="color: #572ae4">E </strong>
                  <a
                    href="mailto:${email}"
                    style="color: #000000; text-decoration: none;"
                  >
                    ${email}
                  </a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
  `
      // Inject HTML into the Shadow DOM
      shadowRoot.innerHTML = signatureHTML
    }
  }, [name, role, phone, email])

  return (
    <div style={{ backgroundColor: '#f5f5f5', padding: '20px' }}>
      <form style={{ color: '#333' }}>
        <label>
          Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
            style={{ marginLeft: '10px', padding: '5px', width: '300px' }}
          />
        </label>
        <br />
        <br />
        <label>
          Role:
          <input
            type="text"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            placeholder="Enter your role"
            style={{ marginLeft: '10px', padding: '5px', width: '300px' }}
          />
        </label>
        <br />
        <br />
        <label>
          Phone:
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Enter your phone number"
            style={{ marginLeft: '10px', padding: '5px', width: '300px' }}
          />
        </label>
        <br />
        <br />
        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            style={{ marginLeft: '10px', padding: '5px', width: '300px' }}
          />
        </label>
        <br />
        <br />
      </form>

      <div style={{ backgroundColor: '#fff', padding: '20px' }}>
        <p style={{ color: 'red', fontWeight: 'bold', marginBottom: '20px' }}>
          Copy the below signature within the box and paste it into your email
          signature in Outlook or Gmail. Do not copy the box itself.
        </p>
        <div
          style={{
            border: '1px solid #ddd',
            padding: '20px',
            borderRadius: '5px',
            fontFamily: 'Heveltica, sans-serif',
            width: '100%',
            height: 'auto',
            backgroundColor: 'transparent',
          }}
        >
          <div ref={shadowRootRef} />
        </div>
      </div>
    </div>
  )
}

export default EmailSignature
