import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`General guidance`}</h2>
    <p>{`Tab Group organizes content across different screens, data sets, and other interactions.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`For switching between views on a page (not selecting options as you would using a button group). Tab Group organizes and allows navigation between groups of content that are related and at the same level of hierarchy.`}</p>
    <p>{`All individual tabs stays together to compose a Tab Group. The tab group should be left aligned with the card component. The active tab has a yellow bar at the top to make it noticeable. There is no padding between individual tabs. It always should have a leading icon in the tab.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      