import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`General guidance`}</h2>
    <p>{`Use breadcrumbs to note the path of a record and help the user to navigate back to the parent. The bottommost level will not be clickable, as the user is already on that page.`}</p>
    <p>{`Note: The breadcrumbs should note all levels of organization, even if not all the levels have their own pages. If there is not a dedicated page for a given level, it will not be clickable.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      