import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Badge } from '@dv01-inc/waterfall-ui';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Design</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Display</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Variations</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Colors</AnchorLink>
    </AnchorLinks>
    <h2>{`General guidance`}</h2>
    <p>{`Badges are labels which hold small amounts of information. They may or may not contain an icon, and they are not clickable.`}</p>
    <h2>{`Design`}</h2>
    <p>{`There are no states for badges, as they are labels and therefore not clickable. They can be created, added to an item, removed from an item, and/or archived so that they are no longer able to be used.`}</p>
    <h2>{`Display`}</h2>
    <p>{`Badges may stack on top of each other, or be displayed in-line. In certain instances (such as being displayed in a table in a drawer) where multiple badges are attached to a single entry, one badge may remain visible while the others may be displayed into a menu that opens and closes.`}</p>
    <h2>{`Variations`}</h2>
    <h3>{`Normal`}</h3>
    <p>{`Badge is displayed without an icon. The default color is primary, but all 4 color scales are available.`}</p>
    <Badge variant="primary" mdxType="Badge">Primary</Badge>
    <h3>{`With icon`}</h3>
    <p>{`Badge displayed with an icon. All 4 color scales are available.`}</p>
    <Badge variant="primary" icon="cog" mdxType="Badge">
  Primary
    </Badge>
    <h3>{`Full Width`}</h3>
    <p>{`Can be displayed with or without the icon, and all colors are available.`}</p>
    <Badge variant="primary" fill mdxType="Badge">
  Full Width
    </Badge>
    <h3>{`Data`}</h3>
    <p>{`Used for Data Viz purposes, such as counting the number of entries on the Geo Map page. There are no variable attributes for this variant, meaning the color, font, and/or formatting do not change.`}</p>
    <Badge variant="data" mdxType="Badge">Data</Badge>
    <h2>{`Colors`}</h2>
    <p>{`The default color for the badge is primary. However, all variants (with the exception of the data badge) can use any of the following colors.`}</p>
    <Badge variant="primary" mdxType="Badge">Primary</Badge>
    <Badge variant="secondary" mdxType="Badge">Secondary</Badge>
    <Badge variant="success" mdxType="Badge">Success</Badge>
    <Badge variant="danger" mdxType="Badge">Danger</Badge>
    <Badge variant="warning" mdxType="Badge">Warning</Badge>
    <Badge variant="default" mdxType="Badge">Default</Badge>
    <Badge variant="alternative" mdxType="Badge">Alternative</Badge>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      