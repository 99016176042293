import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { ButtonGroup } from '@dv01-inc/waterfall-ui';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Sizing and Design</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Button States & Button Selection</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Variations</AnchorLink>
    </AnchorLinks>
    <h2>{`General guidance`}</h2>
    <p>{`Button groups are used to bunch together buttons (or menu items) with similar actions.`}</p>
    <p>{`In certain instances, button groups can also be used in lieu of an input box—such as when there are only a few options available from which to choose, and/or when all of the options need to be continuously visible to the user.`}</p>
    <h2>{`Sizing and Design`}</h2>
    <p>{`The default choice is the medium button group. Large and small variations are also available. Buttons on the left and right edges of the button groups will have rounded outer corners and square inner corners (where they connect to other buttons). Note that the large variation will have a different corner radius than the medium and small variants.`}</p>
    <p>{`Button groups should display a minimum of two choices, with a maximum of five. If there are four to five choices, you have the option to use the overflow variation, or consider using an input box instead. For one choice, use a regular button.`}</p>
    <p>{`Button groups can be displayed with or without a label; the default option is to display the label.`}</p>
    <h2>{`Button States & Button Selection`}</h2>
    <p>{`Button groups will have the same states as regular buttons, with the addition of the `}<inlineCode parentName="p">{`active`}</inlineCode>{` state: `}<inlineCode parentName="p">{`Default`}</inlineCode>{`, `}<inlineCode parentName="p">{`hover`}</inlineCode>{`, `}<inlineCode parentName="p">{`on click`}</inlineCode>{`, `}<inlineCode parentName="p">{`active`}</inlineCode>{`, `}<inlineCode parentName="p">{`focus`}</inlineCode>{`, and `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`.`}</p>
    <p>{`Note that a button group can only have one active choice at a given time. However, there can be multiple `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` states in a button group.`}</p>
    <h2>{`Variations`}</h2>
    <h3>{`Default (Text Only)`}</h3>
    <h3>{`With Dropdown`}</h3>
    <p>{`The item with the icon must always appear as the last item on the right.`}</p>
    <h3>{`With Overflow`}</h3>
    <p>{`The icon must always appear as the last item on the right.`}</p>
    <h3>{`Text + Icon`}</h3>
    <p>{`The icon must always appear as the last item on the right.`}</p>
    <h3>{`Icon Only`}</h3>
    <p>{`This variation is functionally the same as the `}<inlineCode parentName="p">{`default`}</inlineCode>{` option, but uses iconography in lieu of text.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      