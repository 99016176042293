import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Usage</AnchorLink>
    </AnchorLinks>
    <h2>{`General guidance`}</h2>
    <p>{`A pill represents the contents of a filter or currently applied items (such as a dataset). Users can view more information about the pills by clicking or hovering over the pills (depending on the different use cases).`}</p>
    <h2>{`Usage`}</h2>
    <p>{`Generally, the pills are primarily used to show applied filters, and to show the current applied dataset(s). With the pills, users can quickly know what filters have been applied on the page, in addition to being able to add/edit/clear the pills. Note that the specific variant (see below) used for a pill will depend on its particular circumstance; pills can have multiple actions available to the user (`}<inlineCode parentName="p">{`edit`}</inlineCode>{` and `}<inlineCode parentName="p">{`delete`}</inlineCode>{` variant), or they can have no actions (`}<inlineCode parentName="p">{`read only`}</inlineCode>{` variant).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      