import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Detailed instructions can be found on the `}<a parentName="p" {...{
          "href": "https://dv01.github.io/waterfall-ui/"
        }}>{`dv01 Waterfall Design System`}</a>{`.`}</p>
    </PageDescription>
    <h2>{`First steps`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Install npm package`}</strong></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`npm install @dv01-inc/waterfall-ui
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Start developing`}</strong></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`npm run storybook
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Make some changes!`}</strong></p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      