import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Usage</AnchorLink>
    </AnchorLinks>
    <h2>{`General guidance`}</h2>
    <p>{`The menu button differs visually from a regular button by featuring an expand icon, which triggers a dropdown menu. By default, this menu is positioned below the button, but it can also be displayed above if there is not enough space.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`Use the menu button if:
You need a menu that provides more than one option.`}</p>
    <p>{`Do not use the menu button if:
The menu provides only one option. In this case, consider using a button instead.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      