import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Usage</AnchorLink>
    </AnchorLinks>
    <h2>{`General guidance`}</h2>
    <p>{`Pagination organizes and divides large amounts of content on separate pages and gives the user control over how much content they want to view on each page. Pagination can be used with a data table or on a page.`}</p>
    <h2>{`Usage`}</h2>
    <ul>
      <li parentName="ul">{`When it could take a considerable amount of time to load the available data at once or in a scrolling view`}</li>
      <li parentName="ul">{`When there is too much data to display on one page or within one view of a component`}</li>
      <li parentName="ul">{`To make large amounts of data more accessible to consume by users`}</li>
      <li parentName="ul">{`To optimize on-page real estate`}</li>
      <li parentName="ul">{`To give users more control over how they view large amounts of information`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      